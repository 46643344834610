import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import './assets/sass/global.scss';
import App from './App';
import { persistor, store } from './redux/store';
import theme from './theme';
import ErrorBoundary from './utils/ErrorBoundary';
import DataDog from './utils/DataDog';

ReactDOM.render(
    <React.StrictMode>
        <DataDog>
            <BrowserRouter>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ThemeProvider theme={theme}>
                            <ErrorBoundary>
                                <App />
                            </ErrorBoundary>
                        </ThemeProvider>
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </DataDog>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

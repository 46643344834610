import { datadogRum } from '@datadog/browser-rum';

const DataDog = ({ children }) => {
    datadogRum.init({
        applicationId: 'e9a26508-835c-4bcc-a003-da0fb838ad14',
        clientToken: 'pub352c137fdab790fcc08aef4d3ea64c44',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us3.datadoghq.com',
        service: 'leagues-web',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        allowedTracingUrls: ['https://leagues-api.utrsports.net/', (url) => url.startsWith('https://leagues-api.utrsports.net/')],
        sessionSampleRate: 40,
        sessionReplaySampleRate: 16,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });

    // Normally, just render children
    return children;
};

export default DataDog;
